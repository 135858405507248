<template>
  <div class="w-full max-w-sm">
    <div class="mb-12 text-center">
      <h1 class="mb-2.5">
        Welkom bij ER-BOX
      </h1>
      <p>Vind snel jouw juiste tekeningen en vraag wijzigingen aan.</p>
    </div>

    <SofieAuthLoginForm>
      <template #after-submit>
        <div class="mt-14 flex justify-center">
          <UiButton
            class="mx-auto"
            type="link"
            :to="{name: 'auth-request-password'}"
            label="Wachtwoord vergeten"
          />
        </div>
      </template>
    </SofieAuthLoginForm>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  auth: {
    unauthenticatedOnly: true,
  },
  layout: 'auth',
});

useHead({
  title: 'Inloggen',
});
</script>
